:root {
    --primary: #151B29;
    --primaryLight: #1C2437;
    --secondary: #D37861;
    --red: #863131;
    --white: #fff;
    --black: #000;
    --grey: #8B9496;
    --triangle-width: 22px;
    --circle-size: 12px;
    --page-height: 78vh;
    --footer-height: 20vh;
    --vertical-line-height: 62%;
  }