@import url(/home/lice/projects/deeper/deeper-react/src/utils/global.css);
@import url(/home/lice/projects/deeper/deeper-react/src/utils/global.css);
@import url(/home/lice/projects/deeper/deeper-react/src/utils/global.css);
@import url(/home/lice/projects/deeper/deeper-react/src/utils/global.css);
@import url(/home/lice/projects/deeper/deeper-react/src/utils/global.css);
@import url(/home/lice/projects/deeper/deeper-react/src/utils/global.css);
:root {
    --primary: #151B29;
    --primaryLight: #1C2437;
    --secondary: #D37861;
    --red: #863131;
    --white: #fff;
    --black: #000;
    --grey: #8B9496;
    --triangle-width: 22px;
    --circle-size: 12px;
    --page-height: 78vh;
    --footer-height: 20vh;
    --vertical-line-height: 62%;
  }
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh !important;
  display:flex !important; 
  flex-direction:column !important; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.router-link {
  text-decoration: none;
}


h1 {
  font-family: 'Sen', sans-serif;
  src: local("Sen ExtraBold"), url(/static/media/Sen-ExtraBold.091c85b4.ttf) format("ttf");
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 3.5em;
  font-weight: 800;
}


h2 {
  font-family: 'Sen', sans-serif;
  src: local("Sen Bold"), url(/static/media/Sen-Bold.f7e19def.ttf) format("ttf");
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  margin: 0;
  /* font-weight: 800; */
}

h3 {

  font-family: 'Sen', sans-serif;
  src: local("Sen ExtraBold"), url(/static/media/Sen-ExtraBold.091c85b4.ttf) format("ttf");
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  margin: 0;
  font-weight: 800;
}

h4,
h5,
h6 {
  font-family: "Sen Regular";
  src: local("Sen Regular"), url(/static/media/Sen-Regular.c92d05eb.ttf) format("ttf");
}

p {
  font-family: 'Sen', sans-serif;
  src: local("Sen Regular"), url(/static/media/Sen-Regular.c92d05eb.ttf) format("ttf");
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* margin: 0; */
}



a {
  color: var(--white);
  text-decoration: none;
}

a:hover {
  color: var(--secondary);
  text-decoration: underline;
  /* text-shadow: 0px 0px 0px var(--white)fff; */
}

h6 {
  color: white;
}

.push-down {
  min-height: var(--page-height);
  line-height: var(--page-height);
}

.spinner-error-content {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

.text-left {
  text-align: left;
}
.App {
  color: var(--white);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--primary);
}

.border-primary {
  box-shadow: inset 0px 0px 0px 3px var(--primary);
  /* max-height: 99vh; */
}

.border-white {
  margin-top: 1vh;
  margin-left: 0.5vw;
  margin-right: 0.5vw;
  box-shadow: inset 0px 1px 0px 1px var(--white);
  height: 100%;
}

.App-link {
  color: #61dafb;
}

.main-container {
  padding-top: 15vh;
  margin-left: 0;
  margin-right: 0;
}

.custom-link {
  text-decoration: none;
}

.custom-link:hover {
  color: var(--white);
}

@media screen and (max-width: 768px) {
  .border-white {
    margin-top: 1.5vh;
    margin-left: 1.5vw;
    margin-right: 1.5vw;
    box-shadow: inset 0px 1px 0px 1px var(--white);
    max-height: 100%;
  }
}
.page-size {
  min-height: var(--page-height);
}
/*# sourceMappingURL=App.css.map */
.main-container {
  padding-top: 15vh;
  margin-left: 0;
  margin-right: 0;
}

.first-section {
  width: 100%;
}

.section-choose-way-to-play {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 3%;
}

.img {
  height: 40vh;
  width: 40vw;
  padding-bottom: 0;
}

.inner-container {
  text-align: center;
  max-height: 50%;
  max-width: 50%;
}

.right-hero-side {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

.game-description-container {
  width: 50%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
}

.svg-container {
  display: none;
}

.game-description {
  font-size: 1.2rem;
  color: #c9c8c8;
}

@media screen and (max-width: 768px) {
  .game-description-container {
    width: 80%;
  }
  .inner-container {
    max-width: 100%;
  }
  .first {
    order: 2;
  }
  .img {
    width: 70vw;
    padding-bottom: 0;
  }
  .svg-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .second {
    order: 1;
  }
  .section-choose-way-to-play {
    order: 3;
    padding: 0;
    padding-bottom: 8%;
  }
  .arrow {
    height: 14vh;
    width: 14vw;
  }
  .circle-line {
    height: 10vh;
    width: 10vw;
  }
}
/*# sourceMappingURL=Home.css.map */
.btn {
  border-radius: 12px;
  padding: 1rem;
}

.light-button {
  color: white;
  background-color: #3c485f;
  border-radius: 8px;
  margin-right: 2px;
  margin-top: 5px;
  margin-bottom: 5px;
  min-width: 100%;
  border: 0.5px solid var(--white);
  text-transform: uppercase;
}

.light-button:hover {
  background-color: var(--primaryLight);
  color: white;
}

.home-button {
  color: white;
  background-color: var(--primary);
  margin-right: 2px;
  margin-top: 5px;
  margin-bottom: 5px;
  min-width: 100%;
  border: 0.5px solid var(--white);
  text-transform: uppercase;
}

.home-button:hover {
  background-color: #4f586b;
  color: white;
}

.next-button {
  font-weight: bold;
  background-color: var(--secondary);
  border-radius: 8px;
  margin-right: 2px;
  margin-top: 5px;
  margin-bottom: 5px;
  min-width: 100%;
  min-width: 100%;
  text-transform: uppercase;
}

.next-button:hover {
  background-color: var(--red);
  color: white;
}
/*# sourceMappingURL=Button.css.map */
.shape {
  background-image: radial-gradient(300px 30vh at 50% -5vh, var(--secondary) 75%, transparent 75%);
  height: 20vh;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  contain: content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 10;
  position: absolute;
}

.page-title {
  margin-top: 3vh;
  color: var(--primary);
}
/*# sourceMappingURL=HeaderLG.css.map */
.vertical-line {
  width: 0%;
  height: var(--vertical-line-height);
  top: 0;
  background: white;
  position: absolute;
  left: 50%;
  border: 0.5px solid white;
}

.circle {
  position: absolute;
  /* calculate the center of the page minus the half of own size */
  /* Firefox */
  /* left: -moz-calc(50% - (var(--circle-size) / 2)); */
  /* WebKit */
  /* left: -webkit-calc(50% - (var(--circle-size) / 2)); */
  /* Opera */
  /* left: -o-calc(50% - (var(--circle-size) / 2)); */
  /* Standard */
  left: calc(50% - (var(--circle-size) / 2));
  top: calc(var(--vertical-line-height) - 7%);
  height: var(--circle-size);
  width: var(--circle-size);
  background-color: var(--white);
  border-radius: 50%;
  display: inline-block;
}

.triangle-base {
  position: absolute;
  /* calculate the center of the page minus the half of own size */
  /* Firefox */
  /* left: -moz-calc(50% - (var(--triangle-width)/2)); */
  /* WebKit */
  /* left: -webkit-calc(50% - (var(--triangle-width) / 2)); */
  /* Opera */
  /* left: -o-calc(50% - (var(--triangle-width) / 2)); */
  /* Standard */
  left: calc(50% - (var(--triangle-width) / 2));
}

.triangle-top {
  top: calc(var(--vertical-line-height) - 2%);
}

.triangle-bottom {
  top: calc(var(--vertical-line-height) - 0.5%);
}

@media screen and (max-width: 768px) {
  .line-container {
    display: none;
  }
}
/*# sourceMappingURL=VerticalLine.css.map */
.triangle {
  position: relative;
  -webkit-transform: scale(1);
  -webkit-transform: scale(var(--ggs, 1));
          transform: scale(1);
          transform: scale(var(--ggs, 1));
  width: var(--triangle-width);
  height: 17px;
  border-left: 1px solid transparent;
  border-bottom: 1px solid;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.triangle,
.triangle::before {
  display: block;
  box-sizing: border-box;
  border-right: 1px solid transparent;
}

.triangle::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-left: 1px solid;
  border-top: 1px solid;
  border-bottom: 1px solid transparent;
  -webkit-transform: rotate(45deg) skew(10deg, 10deg);
          transform: rotate(45deg) skew(10deg, 10deg);
  /* left: -1px; */
  bottom: -11px;
}
/*# sourceMappingURL=Triangle.css.map */
.category-card {
  width: 300px;
  height: 300px;
  border-radius: 5px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  cursor: pointer;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  border: white 1px solid;
  background-color: var(--primary);
  margin: 10px auto;
}

.category-card:hover .profile-content {
  background-color: var(--white);
}

.category-card:hover h3,
.category-card:hover h6 {
  color: var(--primaryLight);
}

.category-card:hover .start-button {
  visibility: visible;
}

.category-card:hover .icons {
  color: var(--secondary);
}

.category-card:hover .number {
  color: #4f586b;
}

.profile-content {
  padding: 15px;
  background-color: var(--primaryLight);
  width: 100%;
}

.category-title {
  height: 25%;
}

.icons {
  padding: 10%;
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
  color: var(--white);
}

.profile-description {
  color: var(--grey);
  font-size: 12px;
  padding: 10px;
  height: 15%;
}

.profile-overview {
  padding: 1vh;
}

.profile-overview p {
  font-size: 10px;
  font-weight: 600;
  color: var(--grey);
}

.border {
  border: 1px solid #dee2e6 !important;
}

.start-button {
  visibility: hidden;
}

.number {
  color: white;
}
/*# sourceMappingURL=Category.css.map */
.main-container {
  padding-top: 20vh;
  margin-left: 0;
  margin-right: 0;
}

.categories-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
/*# sourceMappingURL=Categories.css.map */
.spinner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: var(--white);
}
/*# sourceMappingURL=Spinner.css.map */
/* No CSS *//*# sourceMappingURL=Error.css.map */
.contain {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-top: 1rem;
  padding-bottom: 3rem;
}

.contain .title {
  padding-bottom: 3rem;
}

.contain h6 {
  padding-bottom: 1.5rem;
  color: var(--grey);
}

.contact-boxes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: stretch;
  min-width: 60%;
  padding-bottom: 3rem;
}

@media (max-width: 767px) {
  .contact-boxes {
    max-width: 90%;
  }
}

.contact-box {
  border: 1px white solid;
  padding: 6%;
  border-radius: 2px;
  width: 40%;
}

@media (max-width: 781px) {
  .contact-box {
    margin-bottom: 3%;
    width: 100%;
  }
}

.contact {
  padding-bottom: 1rem;
  padding-top: 0.5rem;
}

.contact-boxes .icon {
  padding-right: 2rem;
  color: var(--secondary);
}
/*# sourceMappingURL=Contact.css.map */
.page-title-small {
  padding: 1.5%;
}
/*# sourceMappingURL=HeaderSM.css.map */
/* No CSS *//*# sourceMappingURL=Rules.css.map */
/* No CSS *//*# sourceMappingURL=Impressum.css.map */
.info-boxes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: stretch;
  padding-bottom: 3rem;
  max-width: 80%;
}

@media (max-width: 767px) {
  .info-boxes {
    max-width: 90%;
  }
}

.info-box {
  border: 1px white solid;
  padding: 5%;
  border-radius: 2px;
  width: 44%;
}

@media (max-width: 767px) {
  .info-box {
    width: 100%;
    margin-bottom: 3%;
  }
}

.avatar {
  max-width: 100px;
  height: auto;
  padding-bottom: 2rem;
  border-radius: 100%;
}
/*# sourceMappingURL=About.css.map */
.custom-card {
  text-align: center;
  font-size: calc(10px + 2vmin);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;
  min-height: 64vh;
}

.card-border {
  background-color: white;
  color: var(--primaryLight);
  border-radius: 5px;
  border: 5px solid var(--primary);
  /* outline: 0.1px solid rgb(255, 255, 255); */
  box-shadow: 0px 0px 0px 1px #fff;
  width: 60vw;
}

.card-icons {
  padding: 3rem;
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
  color: var(--secondary);
}

.question {
  font-weight: bolder;
  margin: 0;
  padding: 4rem;
}

.separator-div {
  order: 1;
  display: flex;
  justify-content: center;
}

.separator {
  display: block;
  width: 15vw;
}

.home {
  order: 2;
}

.next {
  order: 3;
}

.card-content {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

@media screen and (max-width: 578px) {
  .next {
    order: 1;
    margin: 2% 0;
    width: 60vw;
  }
  .separator-div {
    order: 2;
    margin-bottom: 2%;
  }
  .home {
    order: 3;
    width: 60vw;
  }
}
/*# sourceMappingURL=Card.css.map */
footer {
  background-color: var(--primaryLight);
  width: 100%;
  padding-top: 5vh;
  padding-bottom: 5vh;
  display: flex;
  min-height: var(--footer-height);
  margin-top: auto;
}

.footer-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  align-content: flex-start;
}

.border-right {
  box-shadow: 2px 0px 0px -1px #ffffff;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.inner-footer-container {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  .border-right {
    box-shadow: 0px 0px 0px 0px #ffffff;
  }
  .inner-footer-container {
    width: 100%;
    padding: 5%;
  }
  .container {
    padding-bottom: 5%;
  }
}
/*# sourceMappingURL=Footer.css.map */
/* No CSS *//*# sourceMappingURL=Template.css.map */
.contain h2 {
    padding-bottom: 3rem;
}
