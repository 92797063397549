@import "./utils/global.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh !important;
  display:flex !important; 
  flex-direction:column !important; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.router-link {
  text-decoration: none;
}


h1 {
  font-family: 'Sen', sans-serif;
  src: local("Sen ExtraBold"), url(./assets/fonts/Sen/Sen-ExtraBold.ttf) format("ttf");
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 3.5em;
  font-weight: 800;
}


h2 {
  font-family: 'Sen', sans-serif;
  src: local("Sen Bold"), url(./assets/fonts/Sen/Sen-Bold.ttf) format("ttf");
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  margin: 0;
  /* font-weight: 800; */
}

h3 {

  font-family: 'Sen', sans-serif;
  src: local("Sen ExtraBold"), url(./assets/fonts/Sen/Sen-ExtraBold.ttf) format("ttf");
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  margin: 0;
  font-weight: 800;
}

h4,
h5,
h6 {
  font-family: "Sen Regular";
  src: local("Sen Regular"), url(./assets/fonts/Sen/Sen-Regular.ttf) format("ttf");
}

p {
  font-family: 'Sen', sans-serif;
  src: local("Sen Regular"), url(./assets/fonts/Sen/Sen-Regular.ttf) format("ttf");
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* margin: 0; */
}



a {
  color: var(--white);
  text-decoration: none;
}

a:hover {
  color: var(--secondary);
  text-decoration: underline;
  /* text-shadow: 0px 0px 0px var(--white)fff; */
}

h6 {
  color: white;
}

.push-down {
  min-height: var(--page-height);
  line-height: var(--page-height);
}

.spinner-error-content {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

.text-left {
  text-align: left;
}